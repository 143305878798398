import * as React from 'react'
import * as styles from '../../assets/styles.module.scss'
import { default as Header } from '../common/header'
import { default as Heroshot } from '../common/heroshot'
import { default as Footer } from '../common/footer'

import { default as Tagline } from '../homepage/Tagline'
import { default as Services } from '../homepage/Services'
import { default as Promise } from '../homepage/Promise'
import { default as Customers } from '../homepage/Customers'
import { default as Offering } from '../homepage/Offering'
import { default as Contact } from '../homepage/Contact'

interface PageProps {}

export default class Page extends React.Component<PageProps, {}> {

    constructor(props: any) {
        super(props)
    }

    public render() {
        return (
            <section className={styles.Container}>
                <Header />
                <Heroshot title={`Amamos resolver problemas de negocios utilizando tecnología, diseño e innovación.`}
                subtitle={`
                    We partner with small, mid-size and enterprises to 
                    design and develop game-changing digital products
                    and services, from napkin to market.`} />
                <div className={styles.Homepage}>
                    <Tagline />
                    <Services />
                    <Promise />
                    <Offering />
                    <Customers />
                    <Contact />
                    {/* <Process /> */}
                </div>
                <Footer />
            </section>
        )
    }
}